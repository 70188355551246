import { scsFetch } from 'shared/scs/scsFetch'

import * as shippedPackages from 'shared/scs/shippedPackages'
import * as shippedPackageLineItems from 'shared/scs/shippedPackageLineItems'

function setParam(obj, key, value) {
  if (value) { obj[key] = value }
}

export default {
  // App stuff

  async getAppVersion(versionType) {
    const admin = versionType === 'admin'
    const resp = await scsFetch('app_version', "GET", { admin })
    return resp.json()
  },

  // Affiliates

  async createAffiliate(user) {
    const resp = await scsFetch('/affiliates', 'POST', { user_id: user.id })
    return resp.json()
  },

  getAffiliates(data = {}) {
    return scsFetch("/affiliates", "GET", data)
  },

  async getAffiliate(id) {
    const resp = await scsFetch(`/affiliates/${id}`)
    return resp.json()
  },

  async payAffiliate(id, data = {}) {
    const resp = await scsFetch(`/affiliates/${id}/pay`, 'POST', data)
    return resp.json()
  },

  // Attachments

  deleteAttachment(attachment) {
    return scsFetch(`/attachments/${attachment.id}`, "DELETE")
  },
  attachmentUploadUrl(file, resourceType, resourceId) {
    return scsFetch("/attachments/upload", "POST", {
      filename: file.name,
      resource_type: resourceType,
      resource_id: resourceId
    }).then(
      (response) => response.json()
    )
  },
  createAttachment(uploadUrl, resourceType, resourceId, category = "file") {
    return scsFetch("/attachments", "POST", {
      category,
      filename: uploadUrl,
      resource_type: resourceType,
      resource_id: resourceId
    }).then((response) => response.json())
  },

  // Design Requests

  createDesignRequest(data) {
    return scsFetch("/design_requests", "POST", data).then((response) => response.json())
  },

  getDesignRequest(id) {
    return scsFetch(`/design_requests/${id}`, "GET").then((response) => response.json())
  },

  // Resource Audits

  getResourceAudits(data) {
    return scsFetch(`/${data.path}/${data.id}/audits`, "GET", data).then((response) => response.json())
  },

  // Resource Invitations

  getResourceInvitations(data) {
    return scsFetch(`/${data.path}/${data.id}/invites`, "GET", data).then((response) => response.json())
  },

  resendResourceInvitation(data) {
    return scsFetch(`/${data.path}/${data.id}/invites/${data.invite_id}`, "PUT").then((response) => response.json())
  },

  deleteResourceInvitation(data) {
    return scsFetch(`/${data.path}/${data.id}/invites/${data.invite_id}`, "DELETE").then((response) => response.json())
  },

  // Resource Locations

  getResourceLocations(data) {
    return scsFetch(`/resource_locations`, "GET", data).then((response) => response.json())
  },

  createResourceLocations(data) {
      return scsFetch(`/resource_locations`, "POST", data).then((response) => response.json())
    },

  // Cart

  acceptSavedCart(data) {
    return scsFetch(`/saved_carts/accept`, "POST", data).then((response) => response.json())
  },

  cloneCartItem(data) {
    return scsFetch(`/cart/${data.id}/clone`, "POST").then((response) => response.json())
  },

  createCartItem(data) {
    return scsFetch("/cart", "POST", data).then((response) => response.json())
  },

  createBatchCartItems(data) {
    return scsFetch("/cart/create_batch", "POST", data).then((response) => response.json())
  },

  createCartFromOrder(data) {
    return scsFetch("/cart/create_from_order", "POST", data).then((response) => response.json())
  },

  deleteCartItem(data) {
    return scsFetch(`/cart/${data.id}`, "DELETE").then((response) => response.json())
  },

  deleteMultipleCartItems(data) {
    return scsFetch(`/cart/delete_multiple`, "DELETE", data).then((response) => response.json())
  },

  getCart(id, data) {
    return scsFetch(`/cart/${id}`, "GET", data).then((response) => response.json())
  },

  getCartItems(data) {
    return scsFetch("/cart", "GET", data).then((response) => response.json())
  },

  async getShippingRates(data) {
    return scsFetch('/cart/rates', "GET", data).then((response) => response.json())
  },

  async getDeliveryOptions(data) {
    return scsFetch('/cart/delivery', "GET", data).then((response) => response.json())
  },

  previewSavedCart(data) {
    return scsFetch(`/saved_carts/preview`, "GET", data)
           .then((response) => response.json())
           .catch((error) => {
              let resp
              try {
                // Try to parse some data out of the error. scsFetch ensures errors are strings
                // so any JSON needs to be parsed.
                resp = JSON.parse(error)
              } catch {
                resp = {}
              }

              // If we found a redirect_to link, let the toast message say we're redirecting and
              // redirect to the new location.
              if (resp.redirect_to) {
                window.location.href = resp.redirect_to
                throw new Error('Redirecting...')
              }

              // If we couldn't parse the error specifically, re-raise the error that landed us here
              throw error
           })
  },

  updateCartItem(data) {
    return scsFetch(`/cart/${data.id}`, "PUT", data).then((response) => response.json())
  },

  // Commissions

  getCommissions(data) {
    return scsFetch('/commissions', 'GET', data)
  },

  // Customers

  createCustomer(data) {
    return scsFetch("/customers", "POST", data).then((response) => response.json())
  },
  getCustomer(data) {
    return scsFetch(`/customers/${data.id}`, "GET", data).then((response) => response.json())
  },
  getCustomers(data) {
    return scsFetch("/customers", "GET", data)
  },
  updateCustomer(data) {
    return scsFetch(`/customers/${data.id}`, "PUT", data).then((response) => response.json())
  },

  // Customer Products

  createCustomerProduct(data) {
    return scsFetch("/customer_products", "POST", data).then((response) => response.json())
  },

  getCustomerProduct(data) {
    return scsFetch(`/customer_products/${data.id}`, "GET").then((response) => response.json())
  },

  getCustomerProducts(data) {
    return scsFetch("/customer_products", "GET", data)
  },

  updateCustomerProduct(product) {
    return scsFetch(`/customer_products/${product.id}`, "PUT", product).then((response) => response.json())
  },

  deleteCustomerProduct(product) {
    return scsFetch(`/customer_products/${product.id}`, "DELETE").then((response) => response.json())
  },

  // Drawings

  createDrawingQuoteOverride(override) {
    return scsFetch(`/drawings/${override.drawing_id}/overrides`, "POST", override)
      .then((response) => response.json())
  },
  deleteDrawing(data) {
    return scsFetch(`/drawings/${data.id}`, "DELETE").then(
      (response) => response.json()
    )
  },
  deleteMultipleDrawings(data) {
    return scsFetch(`/drawings/delete_multiple`, "DELETE", data).then((response) => response.json())
  },
  async downloadDXF(filename, drawing, lineItemId, clientId, cartItemId) {
    await scsFetch(`/drawings/${drawing.id}/dxf`, "POST", {
      client_id: clientId, filename, line_item_id: lineItemId, cart_item_id: cartItemId
    })
  },
  deleteDrawingQuoteOverride(override) {
    return scsFetch(`/drawings/${override.drawing_id}/overrides/${override.id}`, "DELETE")
  },
  getDrawings(data) {
    return scsFetch("/drawings", "GET", data)
  },

  drawingUploadUrl(file) {
    return scsFetch("/drawings/upload", "POST", { filename: file.name }).then(
      (response) => response.json()
    )
  },

  async drawingS3Urls(files) {
    const fnames = files.map(f => f.name)
    const response = await scsFetch("/drawings/batch_upload", "POST", { filenames: fnames })
    return response.json()
  },

  async reprocessDrawing(drawingID) {
    const result = await scsFetch(`/drawings/${drawingID}/reprocess`, "POST")
    return result.json()
  },

  refreshDrawing(data) {
    const refreshUrl = `/drawings/${data.id}`
    return scsFetch(refreshUrl, 'GET', data).then((response) => response.json())
  },

  async updateDrawing(data) {
    const params = {
      user_token: data.user_token,

      add_resource_type: data.add_resource_type,
      add_resource_id: data.add_resource_id,
      customer_filename: data.customer_filename,
      filename: data.filename,
      folder_id: data.folder_id,
      remove_resource_type: data.remove_resource_type,
      remove_resource_id: data.remove_resource_id,
      units: data.units,
      unlink_cut_drawing: data.unlink_cut_drawing,

      // For files converted through the drawing-api
      conversion: data.conversion,

      // For quoting
      quantity: data.quantity,
      user_material: data.user_material,
      include_shipping: data.include_shipping
    }

    if (data.kill) {
      params.kill = true
    }

    const response = await scsFetch(`/drawings/${data.id}`, 'PUT', params)
    return response.json()
  },
  updateDrawingQuoteOverride(data) {
    return scsFetch(`/drawings/${data.drawing_id}/overrides/${data.id}`, "PUT", {
      unit_cost: data.unit_cost,
    }).then((response) => response.json())
  },

  async createDrawing(uploadUrl, data = {}) {
    const params = {
      user_token: data.user_token,
      filename: uploadUrl,
      email: "SUPPORT@SENDCUTSEND.COM",
    }

    // New Drawing attributes
    setParam(params, 'add_resource_type', data.add_resource_type)
    setParam(params, 'add_resource_id', data.add_resource_id)
    setParam(params, 'filename', data.filename)
    setParam(params, 'folder_id', data.folder_id)
    setParam(params, 'remove_resource_type', data.remove_resource_type)
    setParam(params, 'remove_resource_id', data.remove_resource_id)
    setParam(params, 'units', data.units)
    setParam(params, 'builder', data.builder)
    setParam(params, 'folder_id', data.folderId)
    setParam(params, 'original_id', data.original_id)

    // Set conversion token from drawing-api (if present)
    setParam(params, 'conversion', data.conversion)
    setParam(params, 'processor_version', data.processor_version)

    const response = await scsFetch("/drawings", "POST", params)
    return response.json()
  },

  // Discounts

  createDiscount(data) {
    return scsFetch("/discounts", "POST", data).then((response) => response.json())
  },
  deleteDiscount(data) {
    return scsFetch(`/discounts/${data.id}`, "DELETE").then(
      (response) => response.json()
    )
  },
  getDiscount({ id }) {
    return scsFetch(`/discounts/${id}`, 'GET')
      .then(response => response.json())
  },
  getDiscountResources(data) {
    return scsFetch("/discounts/resources", 'GET', data)
      .then(response => response.json())
  },
  getDiscounts(data) {
    return scsFetch("/discounts", "GET", data)
  },
  updateDiscount(data) {
    return scsFetch(`/discounts/${data.id}`, "PUT", data).then((response) => response.json())
  },

  // Files

  uploadFileToS3(uploadUrl, file) {
    return fetch(uploadUrl, { method: "PUT", body: file })
  },

  // Finish Options

  createFinishOption(data) {
    return scsFetch("/finish_options", "POST", data).then((response) => response.json())
  },
  deleteFinishOption(data) {
    return scsFetch(`/finish_options/${data.id}`, "DELETE").then(
      (response) => response.json()
    )
  },
  getFinishOption({ id }) {
    return scsFetch(`/finish_options/${id}`, 'GET')
      .then(response => response.json())
  },
  getFinishOptions(data) {
    return scsFetch("/finish_options", "GET", data)
  },
  updateFinishOption(data) {
    return scsFetch(`/finish_options/${data.id}`, "PUT", data).then((response) => response.json())
  },

  // Folders

  createFolder(data) {
    return scsFetch("/folders", "POST", data).then((response) => response.json())
  },
  deleteFolder(data) {
    return scsFetch(`/folders/${data.id}`, "DELETE")
      .then(resp => resp.json())
  },
  getFolders(data) {
    return scsFetch("/folders", "GET", data).then((response) => response.json())
  },
  updateFolder(data) {
    return scsFetch(`/folders/${data.id}`, "PUT", {
      name: data.name,
      description: data.description
    }).then((response) => response.json())
  },

  // Hardware

  createHardware(data) {
    return scsFetch("/hardware", "POST", data)
      .then(resp => resp.json())
  },
  deleteHardware(data) {
    return scsFetch(`/hardware/${data.id}`, "DELETE")
      .then(resp => resp.json())
  },
  fetchHardware(data) {
    return scsFetch("/hardware", "GET", data)
  },
  getHardware(data) {
    return scsFetch(`/hardware/${data.id}`, "GET")
      .then(resp => resp.json())
  },
  updateHardware(data) {
    return scsFetch(`/hardware/${data.id}`, "PUT", data)
      .then(resp => resp.json())
  },

  // Log In / Sign Up

  login(data) {
    return scsFetch("/user_sessions", "POST", data).then((response) => response.json())
  },
  signup(data) {
    return scsFetch("/users", "POST", data).then((response) => response.json())
  },

  // Links

  link(data) {
    return scsFetch("/links", "POST", data).then((response) => response.json())
  },

  // Locations

  getLocations(data) {
    return scsFetch("/locations", "GET", data).then((response) => response.json())
  },
  updateLocation(data) {
    return scsFetch(`/locations/${data.id}`, "PUT", data).then((response) => response.json())
  },

  // Organizations

  async acceptOrganization(data) {
    const resp = await scsFetch(`/organizations/${data.id}/accept`, "POST", data)
    return resp.json()
  },
  async createOrganization(data) {
    const resp = await scsFetch("/organizations", "POST", data)
    return resp.json()
  },
  async getOrganizations(data) {
    return scsFetch("/organizations", "GET", data)
  },
  async getOrganization(id, data = {}) {
    const resp = await scsFetch(`/organizations/${id}`, "GET", data)
    return resp.json()
  },
  async getOrganizationStatement(id, data = {}) {
    const resp = await scsFetch(`/organizations/${id}/statement`, "GET", data)
    return resp.json()
  },
  async inviteOrganization(data) {
    const resp = await scsFetch(`/organizations/${data.id}/invite`, "POST", data)
    return resp.json()
  },
  async joinOrganization(data) {
    const resp = await scsFetch(`/organizations/${data.id}/join`, "POST")
    return resp.json()
  },
  async leaveOrganization(data) {
    const resp = await scsFetch(`/organizations/${data.id}/leave`, "POST", data)
    return resp.json()
  },
  async updateOrganization(data) {
    const resp = await scsFetch(`/organizations/${data.id}`, "PUT", data)
    return resp.json()
  },
  async deleteOrganization(data) {
    const resp = await scsFetch(`/organizations/${data.id}`, "DELETE")
    return resp.json()
  },

  // Materials

  createMaterial(data) {
    return scsFetch("/materials", "POST", data)
      .then(resp => resp.json())
  },
  deleteMaterial(data) {
    return scsFetch(`/materials/${data.id}`, "DELETE")
      .then(resp => resp.json())
  },
  getMachines(data) {
    return scsFetch("/machines", "GET", data)
  },
  async createMachine(data) {
    const resp = await scsFetch('/machines/', 'POST', data)
    return resp.json()
  },
  deleteMachine(id) {
    return scsFetch(`/machines/${id}`, 'DELETE')
  },
  async updateMachine(data) {
    const resp = await scsFetch(`/machines/${data.id}`, 'PUT', data)
    return resp.json()
  },
  getMaterials(data) {
    return scsFetch("/materials", "GET", data)
  },
  getMaterial(data) {
    return scsFetch(`/materials/${data.id}`, "GET")
      .then(resp => resp.json())
  },
  updateMaterial(data) {
    return scsFetch(`/materials/${data.id}`, "PUT", data)
      .then(resp => resp.json())
  },

  exportMaterials(clientId, requestId, exportType) {
    return scsFetch(`/materials/export`, "GET", {
      client_id: clientId, request_id: requestId, export_type: exportType
    }).then(resp => resp.json())
  },

  // Material Inventories

  getMaterialInventories(data) {
    return scsFetch("/material_inventories", "GET", data)
      .then(resp => resp.json())
  },

  getMaterialPendingArea() {
    return scsFetch('/material_inventories/pending')
      .then(resp => resp.json())
  },

  refreshMaterialInventory(data) {
    return scsFetch(`/material_inventories/${data.id}`, "GET", data)
      .then(resp => resp.json())
  },

  findMaterialInventory(data) {
    return scsFetch("/material_inventories/find", "GET", data)
      .then(resp => resp.json())
  },

  updateMaterialInventory(data) {
    return scsFetch(`/material_inventories/${data.id}`, "PUT", data)
      .then(resp => resp.json())
  },

  // Addresses

  createAddress(resource, data) {
    return scsFetch(`/${resource.type}/${resource.id}/addresses`, "POST", data)
      .then(resp => resp.json())
  },
  deleteAddress(resource, data) {
    return scsFetch(`/${resource.type}/${resource.id}/addresses/${data.id}`, "DELETE")
      .then(resp => resp.json())
  },
  getAddresses(resource, data) {
    return scsFetch(`/${resource.type}/${resource.id}/addresses`, "GET", data)
  },
  getAddress(resource, data) {
    return scsFetch(`/${resource.type}/${resource.id}/addresses/${data.id}`, "GET", data)
      .then(resp => resp.json())
  },
  updateAddress(resource, data) {
    return scsFetch(`/${resource.type}/${resource.id}/addresses/${data.id}`, "PUT", data)
      .then(resp => resp.json())
  },

  // Nesting

  async getCuttingSummary() {
    const resp = await scsFetch('/nested_tasks/summary', 'GET')
    return resp.json()
  },

  async getActiveMachines() {
    const resp = await scsFetch('/nested_tasks/machines', 'GET')
    return resp.json()
  },

  async getNestingSchedule(options = {}) {
    const resp = await scsFetch('/nesting/schedule', 'GET', options)
    return resp.json()
  },

  // Nested tasks

  async getNestedTasks(data) {
    return scsFetch('/nested_tasks', 'GET', data)
  },

  async nestedTaskOrders(task) {
    return scsFetch(`/nested_tasks/${task}/orders`, 'GET')
  },

  deleteTask(taskID) {
    return scsFetch(`/nested_tasks/${taskID}`, 'DELETE')
  },

  async updateTask(task) {
    const resp = await scsFetch(`/nested_tasks/${task.id}`, 'PUT', task)
    return resp.json()
  },

  // Nested parts

  async getNestedParts() {
    const resp = await scsFetch('/nested_parts', 'GET')
    return resp.json()
  },

  // Notes

  getNotes(resource, data) {
    return scsFetch(`/${resource.type}/${resource.id}/notes`, 'GET', data)
      .then(resp => resp.json())
  },

  addNote(resource, data) {
    return scsFetch(`/${resource.type}/${resource.id}/notes`, 'POST', data)
      .then(resp => resp.json())
  },

  updateNote(resource, note) {
    return scsFetch(`/${resource.type}/${resource.id}/notes/${note.id}`, 'PUT', note)
      .then(resp => resp.json())
  },

  // Notifications

  getNotifications() {
    return scsFetch(`/notifications`, 'GET')
      .then(resp => resp.json())
  },

  readNotification(id) {
    return scsFetch(`/notifications/${id}/read`, 'POST')
      .then(resp => resp.json())
  },

  readAllNotifications() {
    return scsFetch(`/notifications/read`, 'POST')
      .then(resp => resp.json())
  },

  deleteAllNotifications() {
    return scsFetch(`/notifications/delete`, 'DELETE')
      .then(resp => resp.json())
  },

  // Pending Notifications
  // ex. Material back in stock

  async createPendingNotification(resource, data) {
    const resp = await scsFetch(`/${resource.type}/${resource.id}/pending_notifications`, "POST", data)
    return resp.json()
  },

  // Orders

  batchOrders(data) {
    return scsFetch(`/orders/batch`, "POST", data).then((response) => response.json())
  },

  cloneOrder(orderId, data) {
    return scsFetch(`/orders/${orderId}/clone`, "POST", data).then((response) => response.json())
  },

  createOrder(data) {
    return scsFetch("/orders", "POST", data).then((response) => response.json())
  },

  createOrderLineItem(lineItem) {
    return scsFetch(
      `/orders/${lineItem.order_id}/line_items`,
      "POST",
      lineItem
    ).then((response) => response.json())
  },

  async denestOrderLineItem(clientId, lineItem, drawingIDs) {
    const orderID = lineItem.order_id
    const resp = await scsFetch(
      `/orders/${orderID}/line_items/${lineItem.id}/denest`,
      "POST",
      { client_id: clientId, ids: drawingIDs }
    )
    return resp.json()
  },

  deleteOrderLineItem(lineItem) {
    return scsFetch(
      `/orders/${lineItem.order_id}/line_items/${lineItem.id}`,
      "DELETE"
    )
  },
  deleteOrderShipment(data) {
    return scsFetch(
      `/orders/${data.order_id}/shipments/${data.id}`,
      "DELETE",
      { client_id: data.client_id }
    )
  },

  async fetchLineItems(orderID, data = {}) {
    const resp = await scsFetch(`/orders/${orderID}/line_items`, 'GET', data)
    return resp.json()
  },

  getOrder(data) {
    return scsFetch(`/orders/${data.id}`, "GET", data).then((response) => response.json())
  },

  getOrderInvoice(data) {
    return scsFetch(`/orders/${data.id}/invoice`, "GET", data)
  },

  getOrderStateHistory(data) {
    return scsFetch(`/orders/${data.order_id}/get_state_history`, "GET", data).then((response) => response.json())
  },
  getOrders(data) {
    return scsFetch("/orders", "GET", data)
  },
  searchOrders(data) {
    return scsFetch("/orders/search", "GET", data)
  },
  lockOrder(order) {
    return scsFetch(`/orders/${order.id}/lock`, "PUT", order).then((response) => response.json())
  },
  payOrder(data) {
    return scsFetch(`/orders/${data.order_id}/pay`, "POST", data).then((response) => response.json())
  },
  refreshOrderLineItem(lineItem) {
    return scsFetch(
      `/orders/${lineItem.order_id}/line_items/${lineItem.id}`,
      "GET"
    ).then((response) => response.json())
  },
  refundOrder(data) {
    return scsFetch(`/orders/${data.order_id}/refund`, "POST", data).then((response) => response.json())
  },
  async resetProducedLineItem(lineItem) {
    const response = await scsFetch(`/orders/${lineItem.order_id}/line_items/${lineItem.id}/reset_produced`, "POST")
    return response.json()
  },
  updateOrder(order) {
    return scsFetch(`/orders/${order.id}`, "PUT", order).then((response) => response.json())
  },
  updateOrderLineItem(lineItem) {
    return scsFetch(
      `/orders/${lineItem.order_id}/line_items/${lineItem.id}`,
      "PUT",
      lineItem
    ).then((response) => response.json())
  },

  async recutLineItem(lineItem, recutParams) {
    const response = await scsFetch(`/orders/${lineItem.order_id}/line_items/${lineItem.id}/recut`, 'POST', recutParams)
    return response.json()
  },

  async restoreLineItem(lineItem) {
    const response = await scsFetch(`/orders/${lineItem.order_id}/line_items/${lineItem.id}/restore`, 'POST')
    return response.json()
  },

  async recutLineItemsGroup(id, { lineItemGroupId, recutParams }) {
    const response = await scsFetch(`/orders/${id}/line_items/recut_group`, 'POST', {
      line_item_group_id: lineItemGroupId,
      recut: recutParams
    })
    return response.json()
  },

  async undoRecutLineItem(lineItem) {
    const response = await scsFetch(`/orders/${lineItem.order_id}/line_items/${lineItem.id}/undo_recut`, 'POST')
    return response.json()
  },

  async fetchPreviousOrdersForLineItem(lineItem) {
    const response = await scsFetch(`/orders/${lineItem.order_id}/line_items/${lineItem.id}/previous_orders`, 'GET')
    return response.json()
  },

  async updateLineItemsBatch(id, { state_action: stateAction, line_item_group_id: lineItemGroupId }) {
    const resp = await scsFetch(`/orders/${id}/line_items/update_batch`, "POST", {
      state_action: stateAction,
      line_item_group_id: lineItemGroupId
    })
    return resp.json()
  },

  updateStateHistory(data) {
    return scsFetch(`/orders/${data.order_id}/update_state_history`, "PUT", data)
    .then((response) => response.json())
  },

  shippedPackages,
  shippedPackageLineItems,

  // Parts

  async getParts(data) {
    const resp = await scsFetch('/parts', 'GET', data)
    return resp.json()
  },

  async createPart(item) {
    const resp = await scsFetch('/parts', 'POST', item)
    return resp.json()
  },

  async updatePart(item) {
    const resp = await scsFetch(`/parts/${item.id}`, 'PUT', item)
    return resp.json()
  },

  // Customer Payouts

  createCustomerPayout(data) {
    return scsFetch(`/customer_payouts`, "POST", data)
  },

  getCustomerPayouts(data) {
    return scsFetch(`/customer_payouts`, "GET", data)
  },
  getCustomerPayout(data) {
    return scsFetch(`/customer_payouts/${data.id}`, "GET", data)
      .then(resp => resp.json())
  },
  retryCustomerPayout(id) {
    return scsFetch(`/customer_payouts/${id}/retry`, "POST")
      .then(resp => resp.json())
  },

  // Products

  createProduct(data) {
    return scsFetch("/products", "POST", data).then((response) => response.json())
  },
  deleteProduct(data) {
    return scsFetch(`/products/${data.id}`, "DELETE").then(
      (response) => response.json()
    )
  },
  getProduct({ id }) {
    return scsFetch(`/products/${id}`, 'GET')
      .then(response => response.json())
  },
  getProducts(data) {
    return scsFetch("/products", "GET", data)
  },
  updateProduct(data) {
    return scsFetch(`/products/${data.id}`, "PUT", data).then((response) => response.json())
  },

  // Product Commissions

  getProductCommissions(data) {
    return scsFetch(`/product_commissions`, "GET", data)
  },
  getProductCommission(data) {
    return scsFetch(`/product_commissions/${data.id}`, "GET", data)
      .then(resp => resp.json())
  },
  updateProductCommission(data) {
    return scsFetch(`/product_commissions/${data.id}`, "PUT", data)
      .then(resp => resp.json())
  },

  // Quotes

  getQuotes(data) {
    return scsFetch(`/drawings/${data.drawing_id}/quotes`, "POST", data).then((response) => response.json())
  },

  // Purchase Orders

  async getPurchaseOrder(data) {
    const resp = await scsFetch(`/purchase_orders/${data.id}`, "GET")
    return resp.json()
  },
  async getPurchaseOrders(data) {
    return scsFetch("/purchase_orders", "GET", data)
  },
  async createPurchaseOrder(data) {
    const resp = await scsFetch("/purchase_orders", "POST", data)
    return resp.json()
  },
  async updatePurchaseOrder(data) {
    const resp = await scsFetch(`/purchase_orders/${data.id}`, "PUT", data)
    return resp.json()
  },
  async deletePurchaseOrder(data) {
    const resp = await scsFetch(`/purchase_orders/${data.id}`, "DELETE")
    return resp.json()
  },

  // Purchase Order Line Items

  async getPurchaseOrderLineItems(data) {
    return scsFetch(`/purchase_order_line_items`, "GET", data)
  },
  async getPurchaseOrderLineItem(data) {
    const resp = await scsFetch(`/purchase_order_line_items/${data.id}`, "GET")
    return resp.json()
  },
  async createPurchaseOrderLineItem(data) {
    const resp = await scsFetch(`/purchase_order_line_items`, "POST", data)
    return resp.json()
  },
  async updatePurchaseOrderLineItem(data) {
    const resp = await scsFetch(`/purchase_order_line_items/${data.id}`, "PUT", data)
    return resp.json()
  },
  async deletePurchaseOrderLineItem(data) {
    const resp = await scsFetch(`/purchase_order_line_items/${data.id}`, "DELETE")
    return resp.json()
  },
  async receivePurchaseOrderLineItem(data) {
    const resp = await scsFetch(`/purchase_order_line_items/${data.id}/receive`, "POST", data)
    return resp.json()
  },

  // Referrals

  getReferrals(data) {
    return scsFetch('/referrals', 'GET', data)
  },

  async getReferralStats(affiliateID) {
    const resp = await scsFetch('/referrals/stats', 'GET', { affiliate_id: affiliateID })
    return resp.json()
  },

  // Reports

  getReport(data) {
    return scsFetch("/reports", "GET", data).then((response) => response.json())
  },

  // Saved Cart

  async batchSavedCarts(data) {
    const response = await scsFetch(`/saved_carts/batch`, "POST", data)
    return response.json()
  },
  createSavedCart(data) {
    return scsFetch("/saved_carts", "POST", data).then((response) => response.json())
  },
  createSavedCartFromOrder(data) {
    return scsFetch("/saved_carts/create_from_order", "POST", data).then((response) => response.json())
  },
  cloneSavedCart(data) {
    return scsFetch(`/saved_carts/${data.id}/clone`, "POST", data).then((response) => response.json())
  },
  createSavedCartItem(data) {
    return scsFetch(`/saved_carts/${data.saved_cart_id}/cart_items`, "POST", data).then((response) => response.json())
  },
  deleteSavedCartItem(data) {
    return scsFetch(`/saved_carts/${data.saved_cart_id}/cart_items/${data.id}`, "DELETE")
  },
  deleteSavedCart(data) {
    return scsFetch(`/saved_carts/${data.id}`, "DELETE").then((response) => response.json())
  },
  getSavedCart(data) {
    return scsFetch(`/saved_carts/${data.id}`, "GET", data).then((response) => response.json())
  },
  async fetchSavedCartItems(data) {
    const resp = await scsFetch(`/saved_carts/${data.id}/cart_items`, 'GET')
    return resp.json()
  },
  getSavedCarts(data) {
    return scsFetch("/saved_carts", "GET", data)
  },
  mergeSavedCart(data) {
    return scsFetch(`/saved_carts/${data.dest}/merge`, "POST", { source_saved_cart_ids: data.source }).then((response) => response.json())
  },
  refreshSavedCartItem(data) {
    return scsFetch(`/saved_carts/${data.saved_cart_id}/cart_items/${data.id}`, "GET").then((response) => response.json())
  },
  updateSavedCart(data) {
    return scsFetch(`/saved_carts/${data.id}`, "PUT", data).then((response) => response.json())
  },
  updateSavedCartItem(data) {
    return scsFetch(`/saved_carts/${data.saved_cart_id}/cart_items/${data.id}`, "PUT", data).then((response) => response.json())
  },

  // Saved Package

  createSavedPackage(data) {
    return scsFetch("/saved_packages", "POST", data).then((response) => response.json())
  },
  updateSavedPackage(data) {
    return scsFetch(`/saved_packages/${data.id}`, "PUT", data).then((response) => response.json())
  },
  deleteSavedPackage(data) {
    return scsFetch(`/saved_packages/${data.id}`, "DELETE").then(
      (response) => response.json()
    )
  },
  getSavedPackages(data) {
    return scsFetch("/saved_packages", "GET", data).then((response) => response.json())
  },

  // Settings

  createSetting(data) {
    return scsFetch("/settings", "POST", data).then((response) => response.json())
  },
  deleteSetting(data) {
    return scsFetch(`/settings/${data.id}`, "DELETE").then(
      (response) => response.json()
    )
  },
  getSettings(data) {
    return scsFetch("/settings", "GET", data)
  },
  updateSetting(data) {
    return scsFetch(`/settings/${data.id}`, "PUT", data).then((response) => response.json())
  },

  // Shipments

  createOrderShipment(data) {
    return scsFetch(`/orders/${data.order_id}/shipments`, "POST", data).then((response) => response.json())
  },

  getOrderShipment(data) {
    return scsFetch(`/orders/${data.order_id}/shipments/${data.shipment_id}`, "GET", data).then((response) => response.json())
  },

  getOrderShipmentRates(data) {
    return scsFetch(`/orders/${data.order_id}/shipments/rates`, "POST", data).then((response) => response.json())
  },

  getShipmentEstimate(data) {
    return scsFetch(`/shipments/estimate`, "POST", data).then((response) => response.json())
  },

  // Tickets

  // This is a notification that goes to admins
  createTicket(data) {
    return scsFetch("/tickets", "POST", data).then((response) => response.json())
  },

  // Users

  createUser(data) {
    return scsFetch("/users", "POST", data).then((response) => response.json())
  },

  createPaymentMethod(data) {
    return scsFetch("/users/create_method", "POST", data)
      .then(resp => resp.json())
  },

  deletePaymentMethod(data) {
    return scsFetch("/users/delete_method", "DELETE", data)
      .then(resp => resp.json())
  },

  defaultPaymentMethod(data) {
    return scsFetch("/users/default_method", "POST", data)
      .then(resp => resp.json())
  },

  deleteUser(data) {
    return scsFetch(`/users/${data.id}`, "DELETE", data).then((response) => response.json())
  },

  exportUser(data) {
    return scsFetch(`/users/${data.id}/export`, "POST", data).then((response) => response.json())
  },

  getOtp() {
    return scsFetch("/users/otp", "GET").then((response) => response.json())
  },

  getClientToken() {
    return scsFetch("/users/client_token", "GET")
      .then(resp => resp.json())
  },

  getPublicClientToken() {
    return scsFetch("users/client_token", "GET")
      .then(resp => resp.json())
  },

  getCurrentUser(data) {
    return scsFetch("/users/current", "GET", data).then((response) => response.json())
  },

  getUser(data) {
    return scsFetch(`/users/${data.id}`, "GET", data).then((response) => response.json())
  },

  getUsers(data) {
    return scsFetch("/users", "GET", data)
  },

  mergeUser(data) {
    return scsFetch(`/users/${data.id}/merge`, "POST", data)
  },

  resetUserVault(data) {
    return scsFetch(`/users/${data.id}/reset_vault`, "POST")
  },

  updateUser(data) {
    return scsFetch(`/users/${data.id}`, "PUT", data).then((response) => response.json())
  },
  forceLogoutUser(data) {
    return scsFetch(`/users/${data.id}/invalidate_session`, "POST", data)
      .then(resp => resp.json())
  },

  // Reset Password

  forgot(data) {
    return scsFetch("/reset_password", "POST", data).then((response) => response.json())
  },

  reset(data) {
    return scsFetch("/reset_password", "PUT", data).then((response) => response.json())
  },

  // Vendors

  async createVendor(data) {
    const resp = await scsFetch("/vendors", "POST", data)
    return resp.json()
  },
  async getVendors(data) {
    return scsFetch("/vendors", "GET", data)
  },
  async getVendor(id, data = {}) {
    const resp = await scsFetch(`/vendors/${id}`, "GET", data)
    return resp.json()
  },
  async updateVendor(data) {
    const resp = await scsFetch(`/vendors/${data.id}`, "PUT", data)
    return resp.json()
  },
  async deleteVendor(data) {
    const resp = await scsFetch(`/vendors/${data.id}`, "DELETE")
    return resp.json()
  },

  // Vendors Resources

  async createVendorResource(data) {
    const resp = await scsFetch("/vendor_resources", "POST", data)
    return resp.json()
  },
  async getVendorResources(data) {
    return scsFetch("/vendor_resources", "GET", data)
  },
  async getVendorResource(id, data = {}) {
    const resp = await scsFetch(`/vendor_resource/${id}`, "GET", data)
    return resp.json()
  },
  async updateVendorResource(data) {
    const resp = await scsFetch(`/vendor_resources/${data.id}`, "PUT", data)
    return resp.json()
  },
  async deleteVendorResource(data) {
    const resp = await scsFetch(`/vendor_resources/${data.id}`, "DELETE")
    return resp.json()
  },

  // Support

  async intercomSupport(data) {
    const resp = await scsFetch("/intercom/support", "POST", data)
    return resp.json()
  },

  // Zendesk Data

  upleadData(email) {
    const { token } = window.appData
    return scsFetch("/zendesk/uplead", "GET", { email, token })
      .then(res => res.json())
  },

  zenCustomerSummary(email) {
    const { token } = window.appData
    return scsFetch("/zendesk/summary", "GET", { email, token })
      .then(resp => resp.json())
  },

  zenMatchingCustomers(email) {
    const { token } = window.appData
    return scsFetch("/zendesk/customers", "GET", { email, token })
      .then(resp => resp.json())
  },

  zenNotes({ resource, resource_id: resourceId }) {
    const { token } = window.appData
    return scsFetch("/zendesk/notes", "GET", { type: resource, id: resourceId, token })
      .then(resp => resp.json())
  },

  zenUpdateUser(data) {
    const { token } = window.appData
    return scsFetch("/zendesk/user", "PUT", { ...data, token })
      .then(resp => resp.json())
  },
}
