import scs from 'shared/scs'
import isEmpty from 'lodash/isEmpty'

// initial state
const appState = {
  adminHost: window.appData.admin_host,
  affiliate: null,
  appHost: window.appData.app_host,
  customerProductHost: window.appData.customer_product_host,
  appVersion: null,
  clientGuid: '',
  clientToken: '', // This identifies a client to braintree
  currentUser: null,
  drawingApiHost: window.appData.drawing_api_host,
  drawingApiVersion: window.appData.drawing_api_version,
  helpWidgetEnabled: window.appData.help_widget_enabled,
  initialized: false,
  intercomAppId: window.appData.intercom_app_id,
  intercomApiBase: window.appData.intercom_api_base,
  minDrawingProcessorVersion: window.appData.min_drawing_processor_version,
  recaptchaSiteKey: window.appData.recaptcha_site_key,
  shipEstimateUrl: window.appData.ship_estimate_url,
  usePolyPricing: window.appData.use_poly_pricing,
  userToken: window.appData.user_token, // This identifies a non logged in user
  allowedExtensions: window.appData.allowed_extensions,
  showDSMarketing: window.appData.show_ds_marketing,
  largeCartMarketingMin: window.appData.large_cart_marketing_min
}

// Returns true if the current user is a guest
function isGuest(state) {
  return !state.currentUser || state.currentUser.guest
}

// getters
const appGetters = {
  adminHost: (state) => state.adminHost,
  appHost: (state) => state.appHost,
  customerProductHost: (state) => state.customerProductHost,
  clientGuid: (state) => state.clientGuid,
  clientToken: (state) => state.clientToken,
  currentUser: (state) => state.currentUser,
  currentTerms: (state) => state.currentUser?.terms,
  customerId: (state) => (state.currentUser && state.currentUser.customer ? state.currentUser.customer.id : null),
  defaultUnits: (state) => (state.currentUser ? state.currentUser.default_units : 'auto'),
  // NOTE: As of 7/29/21 we only have a full account permission
  hasPermission: (state) => permission => (permission === 'account' && !isGuest(state)) || permission === '',
  isAuthenticated: (state) => !!state.currentUser?.id,
  isCustomer: (state) => !state.currentUser?.role,
  isGuest: (state) => isGuest(state),
  isPunchOutSession: (state) => state.currentUser && !isEmpty(state.currentUser.punch_out_session),
  largeCartMarketingMin: (state) => state.largeCartMarketingMin,
  punchOutSession: (state) => {
    if (state.currentUser && !isEmpty(state.currentUser.punch_out_session)) {
      return state.currentUser.punch_out_session
    }
    return undefined
  },
  userToken: (state) => state.userToken ?? "",
  recaptchaSiteKey: (state) => state.recaptchaSiteKey,
  requirePO: (state) => state.currentUser && state.currentUser.require_po,
  skipGoogleReview: (state) => state.currentUser.skip_google_review,
  taxExemptAllowed: (state) => state.currentUser && state.currentUser.allow_tax_exempt,
  userEmail: (state) => (state.currentUser && !isGuest(state) ? state.currentUser.email : ''),
  userName: (state) => (state.currentUser && !isGuest(state) ? state.currentUser.name : ''),
}

// actions
const actions = {
  createPaymentMethod(_, data = {}) {
    return scs.createPaymentMethod(data)
  },
  deletePaymentMethod(_, data = {}) {
    return scs.deletePaymentMethod(data)
  },
  defaultPaymentMethod(_, data = {}) {
    return scs.defaultPaymentMethod(data)
  },
  forgot(_, data = {}) {
    return scs.forgot(data)
  },
  getCurrentUser({ commit }, data = {}) {
    return scs.getCurrentUser(data)
        .then((response) => {
          commit('setCurrentUser', response.data)
          return response
        })
        .finally(() => {
          commit('setInitialized')
        })
  },
  async getClientToken({ commit }) {
    return scs.getClientToken()
      .then(resp => commit('setClientToken', resp.data))
  },
  getOtp() {
    return scs.getOtp()
    // Handled in the caller
  },
  login(_, data = {}) {
    return scs.login(data)
  },
  remoteLogout({ getters, commit }) {
    if (getters.isAuthenticated) {
      commit("invalidateSession")

      window.location.replace("/customer#/quote?action=loggedout")
      window.location.reload()
    }
  },
  link(_, data = {}) {
    return scs.link(data)
      .then((response) => (response && response.data ? response.data.url : ""))
  },
  reset(_, data = {}) {
    return scs.reset(data)
  },
  signup({ state }, data = {}) {
    if (state.affiliate) data.affiliate = state.affiliate
    return scs.signup(data)
  },
  updateCurrentUser({ commit, state }, data) {
    return scs.updateUser({ ...data, id: state.currentUser.id })
      .then((response) => {
        commit('setCurrentUser', response.data)
        return response
      })
  }
}

// mutations
const mutations = {
  setAffiliate(state, id) {
    state.affiliate = id
  },
  setClientGuid(state, clientGuid) {
    state.clientGuid = clientGuid
  },
  setClientToken(state, token) {
    state.clientToken = token
  },
  setCurrentUser(state, user) {
    state.currentUser = user
    // Reset the user token if a user logs in.
    if (state.currentUser) {
      state.userToken = null
    }
  },
  setInitialized(state) {
    state.initialized = true
  },
  invalidateSession(state) {
    state.currentUser = null
  }
}

export default {
  namespaced: true,
  state: appState,
  getters: appGetters,
  actions,
  mutations
}
