import ConfirmDialog from 'shared/components/ConfirmDialog'

export default {
  computed: {
    braintree() {
      return window.braintree
    },
    clientToken() {
      return this.$store.getters['app/clientToken']
    },
    customer() {
      return this.$store.getters['customer/get']
    },
    paypal() {
      return window.paypal || {}
    }
  },

  beforeDestroy() {
    if (this.braintreeInstance) this.braintreeInstance.teardown()
    if (this.hostedFieldsInstance) this.hostedFieldsInstance.teardown()
    if (this.paypalCheckoutInstance) this.paypalCheckoutInstance.teardown()
    if (this.vaultManagerInstance) this.vaultManagerInstance.teardown()
  },

  methods: {
    deleteCard(bin) {
      this.$buefy.modal.open({
        parent: this,
        component: ConfirmDialog,
        hasModalCard: true,
        canCancel: true,
        props: {
          message: '<h4>Are you sure you want to remove this payment method?</h4><p>This will not affect any of your open orders that use this method.</p>'
        },
        events: {
          confirm: () => {
            this.loading = true
            this.$store.dispatch('app/deletePaymentMethod', { bin })
            .then(() => {
              this.previousCards()
              this.cardChoice = ''
              this.loading = false
            })
          }
        },
      })
    },
    async initBrainTree() {
      const paymentComponent = this

      if (!paymentComponent.clientToken) {
        try {
          await this.$store.dispatch('app/getClientToken')
            .then(() => {
              this.createBraintree(paymentComponent)
            })
        }
        catch (error) {
          console.error("Error: ", error)
          throw error
        }
      }
      else {
        this.createBraintree(paymentComponent)
      }
    },
    createBraintree(pComponent) {
      pComponent.braintree.client.create({
        authorization: pComponent.clientToken,
      }, (err, instance) => {
        pComponent.braintreeInstance = instance

        if (err) {
          console.error(err)
          return
        }

        // Card in Hosted Fields
        pComponent.braintree.hostedFields.create(pComponent.hostedFieldsConfig, (hFError, hFInstance) => {
          this.hFInstance = hFInstance
          const form = document.querySelector('#braintree-checkout')

          if (hFError) {
            console.error(hFError)
          }

          // On field focus
          hFInstance.on('focus', pComponent.handleHostedFieldsFocus)

          // Off field focus
          hFInstance.on('blur', pComponent.handleHostedFieldsBlur)

          // On submit
          form.addEventListener('submit', event => pComponent.handlePayment(event, hFInstance))
        })

        // PayPal
        pComponent.braintree.paypalCheckout.create({
          client: instance
        }, (paypalCheckoutErr, paypalCheckoutInstance) => {
          this.paypalCheckoutInstance = paypalCheckoutInstance
          paypalCheckoutInstance.loadPayPalSDK({
            currency: 'USD',
            intent: 'capture',
            commit: false,
            vault: true
          }, () => {
            const FUNDING_SOURCES = [
              pComponent.paypal.FUNDING.PAYPAL,
              pComponent.paypal.FUNDING.CREDIT
            ]

            FUNDING_SOURCES.forEach((fundingSource) => {
              const button = pComponent.paypal.Buttons({
                fundingSource,

                createOrder() {
                  return paypalCheckoutInstance.createPayment({
                    flow: 'checkout',
                    amount: pComponent.cartTotal,
                    currency: 'USD',
                    intent: 'capture'
                  })
                },

                onApprove(data) {
                  pComponent.loading = true
                  pComponent.paymentError = ''
                  pComponent.payPalError = ''

                  return paypalCheckoutInstance.tokenizePayment({ ...data, vault: false }, (payPalErr, payload) => {
                    pComponent.payPalApproved = true
                    pComponent.payPalNonce = payload.nonce
                    pComponent.loading = false
                  })
                },

                onCancel() {
                  // Payment Canceled
                },

                onError(payPalError) {
                  if (!payPalError.includes('Detected popup close')) {
                    pComponent.payPalApproved = false
                    pComponent.payPalError = payPalError
                  }
                  else if (!pComponent.payPalApproved) {
                    pComponent.payPalApproved = false
                    pComponent.payPalError = payPalError
                  }
                  pComponent.loading = false
                }
              })

              if (button.isEligible()) {
                if (fundingSource === 'paypal') {
                  button.render('#paypal-button')
                }
              }
            })
          })
        })

        // Vault Manager
        pComponent.braintree.vaultManager.create({
          client: pComponent.braintreeInstance
        }, (vaultManagerErr, vaultManagerInstance) => {
          this.vaultManagerInstance = vaultManagerInstance
          pComponent.vaultInstance = vaultManagerInstance
          pComponent.loading = true
          pComponent.previousCards()
        })
      })
    },
    initBrainTreeVault() {
      const paymentComponent = this

      paymentComponent.braintree.client.create({
        authorization: paymentComponent.clientToken,
      }, (err, instance) => {
        paymentComponent.braintreeInstance = instance

        if (err) {
          console.error(err)
          paymentComponent.loading = false
          return
        }

        // Vault Manager
        paymentComponent.braintree.vaultManager.create({
          client: paymentComponent.braintreeInstance
        }, (vaultManagerErr, vaultManagerInstance) => {
          paymentComponent.vaultInstance = vaultManagerInstance
          paymentComponent.loading = true
          paymentComponent.previousCards()
        })
      })
    },
    makeDefault(bin) {
      this.$buefy.modal.open({
        parent: this,
        component: ConfirmDialog,
        hasModalCard: true,
        canCancel: true,
        props: {
          buttonMessage: 'Make Default',
          message: '<p>Are you sure you want to make this your default payment method?</p>'
        },
        events: {
          confirm: () => {
            this.loading = true
            this.$store.dispatch('app/defaultPaymentMethod', { bin })
            .then(() => {
              this.previousCards()
              this.loading = false
            })
          }
        },
      })
    },
    async previousCards() {
      const paymentComponent = this
      paymentComponent.setLoading(true)

      this.vaultInstance.fetchPaymentMethods({
        defaultFirst: true
      }, (err, paymentMethods) => {
        if (err) {
          paymentComponent.setLoading(false)
          return
        }

        const cardPayments = paymentMethods.filter(method => method.type === "CreditCard")
        if (cardPayments.length === 0) {
          paymentComponent.cardChoice = 'new'
        }
        paymentComponent.previousPayments = cardPayments
        paymentComponent.setLoading(false)
      })
    },
  }
}
